<template>
  <div class="wrapper">
    <v-row no-gutters style="height: 100%">
      <div
        class="col-lg-6 site-banner"
        style="
          background-image: url('https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/e7f911620268d42205f453d67ad6c80c.jpg');
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
        "
      ></div>
      <div class="col-lg-6 site-content">
        <v-row no-gutters>
          <div class="col-lg-8 mx-auto my-5">
            <div v-if="!validatedCode" class="login-register_area card">
              <h6 class="login-title" style="text-align: center">
                Ingresa el código de recuperación que enviamos a tu correo electrónico
              </h6>
              <form action class="form" @submit.prevent="checkCode">
                <v-text-field
                  v-model="code"
                  oninput="if(this.value.length > 6){this.value = this.value.slice(0,6);}"
                  :rules="[rules.required]"
                  type="number"
                  prepend-inner-icon="mdi-lock"
                  label="Código"
                  max="999999"
                  hint=""
                  counter
                  placeholder="000000"
                ></v-text-field>
                <v-row no-gutters style="display: flex; justify-content: center">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    style="color: #fff; background-color: #009ada; border-color: #009ada"
                  >
                    Validar código
                  </button>
                </v-row>
              </form>
            </div>

            <div v-if="validatedCode" class="login-register_area card">
              <h6 class="login-title" style="text-transform: inherit; text-align: center">
                Ingresa y confirma tu nueva contraseña
              </h6>
              <form action class="form" @submit.prevent="checkPassword">
                <div class="col-12">
                  <v-text-field
                    v-model="password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show ? 'text' : 'password'"
                    prepend-inner-icon="mdi-lock"
                    label="Contraseña"
                    hint=""
                    counter
                    placeholder="Ingresa tu contraseña"
                    @click:append="show = !show"
                  ></v-text-field>
                </div>
                <div class="col-12">
                  <v-text-field
                    v-model="passwordConfirmation"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show ? 'text' : 'password'"
                    prepend-inner-icon="mdi-lock"
                    label="Confirmación de contraseña"
                    hint=""
                    counter
                    placeholder="Ingresa tu contraseña"
                    @click:append="show = !show"
                  ></v-text-field>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div style="margin: auto">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        style="
                          color: #fff;
                          background-color: #009ada;
                          border-color: #009ada;
                        "
                      >
                        Restablecer contraseña
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </v-row>
      </div>
    </v-row>
  </div>
</template>
<script>
  export default {
    name: 'Recovery',
    data: () => ({
      show: false,
      email: '',
      code: '',
      password: '',
      passwordConfirmation: '',
      validatedCode: false,
      error: false,
      rules: {
        required: (value) => !!value || 'Requerido',
        min: (v) => v.length >= 6 || 'Escriba por lo menos 6 caracteres',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'El formato de correo electrónico inválido'
        },
      },
    }),
    methods: {
      checkCode() {
        var urlRecoveryFind = process.env.VUE_APP_API_DIRECTORY + 'password/find/'
        axios
          .get(urlRecoveryFind + this.code)
          .then((response) => {
            this.email = response.data.email
            this.validatedCode = true
          })
          .catch((error) => {
            this.$swal({
              title: 'Código inválido',
              text:
                'No hemos encontrado usuarios asociados al código de recuperación que ingresaste en nuestros registros',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#e94e1b',
              showConfirmButton: true,
            })
            this.$swal.showValidationMessage(`Solicitud rechazada: ${error}`)
            setTimeout(() => location.reload, 5000)
            console.log(error)
            this.error = true
          })
      },
      checkPassword() {
        if (this.password == this.passwordConfirmation) {
          this.setPassword()
        } else {
          this.$swal({
            title: 'Falló la confirmación de la contraseña',
            text:
              'La confirmación de la contraseña no coincide. Por favor, intenta nuevamente',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#e94e1b',
            showConfirmButton: true,
          })
        }
      },
      setPassword() {
        var urlSetPassword = process.env.VUE_APP_API_DIRECTORY + 'password/reset'
        axios
          .post(urlSetPassword, {
            email: this.email,
            password: this.password,
            password_confirmation: this.passwordConfirmation,
            token: this.code,
          })
          .then((response) => {
            this.$swal({
              title: 'Proceso completado',
              text: 'Haz restablecido tu contraseña exitosamente',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#003d74',
              showConfirmButton: true,
            })
            this.$router.push('/session/login')
          })
          .catch((error) => {
            console.log(error)
            this.error = true
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    background: #cbd8e1;
    height: 100vh;
    // padding: 25px;
    max-width: 1440px;
    margin: 0 auto;
  }
  .site-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    box-shadow: 0 15px 35px rgb(50 50 93 / 20%), 0 5px 15px rgb(0 0 0 / 15%);
    border: 2px solid rgba(0, 0, 0, 0.1);
  }
  .form {
    width: 80%;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 10px;
    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }
  .v-text-field__details {
    display: none;
    flex: 1 0 auto;
    max-width: 100%;
    min-height: 14px;
    overflow: hidden;
  }
  .col-lg-4,
  .col-md-6,
  .col-sm-12 {
    padding: 0px auto !important;
  }
  .theme--light.v-input {
    color: rgba(0, 0, 0, 0.87);
    padding: 0px;
    margin: 0px;
  }
  .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
    display: flex;
    flex: 1 1 auto;
    position: relative;
    width: 100%;
  }
</style>
